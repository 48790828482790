<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {
      schoolId: null,
      pageTitle: 'Class Group',
    }
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Class Groups',
        route: `/school/${this.schoolId}/class_groups`,
      },
      {
        name: 'Max Scores',
        route: `/school/${this.schoolId}/max_score_info/${this.$route.params.id}`,
      },
    ])
  },
}
</script>
